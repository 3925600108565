#hero {
  margin-top: var(--navbar-height-stuck);
  min-height: 500px;
}
#hero-img {
  min-height: 300px;
  max-width: 300px;
  position: relative;
}
#hero-img >picture>img{
  border-radius: 60%;
  min-height: 300px;
  max-width: 300px;
}
#hero-img::before {
  border-radius: 60%;
  position: absolute;
  content: "";
  min-height: 300px;
  min-width: 300px;
  z-index: -1;
  transform: translateY(-5px);
  filter: blur(10px);
  transition: all ease-in-out 0.6s;
  @apply expand-retract bg-gradient-to-b from-tertiary-500 to-transparent;
}
#hero-img::after {
  border-radius: 60%;
  position: absolute;
  bottom:0;
  content: "";
  min-height: 300px;
  min-width: 300px;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 80%, #16171a);
}
#hero-name,
#hero-title {
  transition:text-shadow ease-in-out 0.6s;
  text-shadow: 0px 0px 21px rgba(137, 43, 226, 0.493);
  @apply text-tertiary-300;
}
#hero-name:hover,
#hero-title:hover {
  transition:text-shadow ease-in-out 0.6s;
  text-shadow: 0px 0px 60px rgba(153, 66, 235, 0.911);
}
.expand-retract {
    animation: expand-retract 3s ease-in-out alternate infinite;
}

@keyframes expand-retract {
    0%{
        filter: blur(10px);
    }
    100%{
        filter: blur(40px);
    }
 }