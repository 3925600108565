:root {
  --navbar-height-stuck: 4rem;
  --navbar-height-unstuck: 4rem;
}
#navbar {
  min-height: 5rem;
  transition: all ease-in-out 0.3s;
}

.stuck {
  position: fixed;
  top: -1px;
  transition: all ease-in-out 0.5s;
  z-index: 99;
}
.stuck > #navbar {
  min-height: 4rem;
  transition: all ease-in-out 0.5s;
}
#navbar a {
  transition: color ease-in-out 0.3s;
}
#navbar a:hover {
  transition: color ease-in-out 0.3s;
  @apply text-tertiary-300;
}

.fade-out-link {
  transition: opacity 0.5s ease-in-out;
}
.hidden {
  opacity: 0;
  display: none;
}

@media only screen and (max-width: 768px) {
  .slideInLeft {
    animation: slideInLeft 0.3s ease-in-out normal forwards;
  }
  .slideOutLeft {
    animation: slideOutLeft 0.3s ease-in-out normal forwards;
  }
}
@keyframes slideInLeft {
  0% {
    transform: translatex(-200%);
  }
  100% {
    transform: translatex(0);
  }
}

@keyframes slideOutLeft {
  0% {
    transform: translatex(0);
  }
  100% {
    transform: translatex(-200%);
  }
}
