@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Montserrat",-apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-primary-500 text-secondary-500;
}
/*
*{ 
  cursor:none;
}

[data-cursor]{
  position: absolute;
  width: 15px;
  height: 15px;
  background: blueviolet;
  border: 4px solid blueviolet;
  border-radius: 50%;
  pointer-events: none;
  transform: rotateX(25deg);
  transition: height, width, border-radius ease-in-out .2s;
  z-index: 999;
}
  
  
.cursor-hover--a [data-cursor]{
  width: 15px;
  height: 15px;
  background: white;
  border-color: blueviolet;
  border-radius: 0% 50% 50% 50%;
  transition: height, width, border-radius ease-in-out .2s;
}

.cursor-hover--gallery-image [data-cursor]{
  width: 15px;
  height: 15px;
  background: blueviolet;
  transform: rotateX(25deg);
  border-color: blueviolet;
  border-radius: 15%;
  transition: height, width, border-radius ease-in-out .2s;
}
*/
