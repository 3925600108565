.gallery-image{
    border-radius: 4px;
    -webkit-box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.3);
    transition: all ease-in-out 0.3s;
}
.gallery-image:hover{
    border-radius: 4px;
    -webkit-box-shadow: 0px 5px 50px 0px rgba(138, 43, 226, 0.5);
    -moz-box-shadow: 0px 5px 50px 0px rgba(138, 43, 226, 0.5);
    box-shadow: 0px 5px 50px 0px rgba(138, 43, 226, 0.5);
    transition: all ease-in-out 0.3s;
}

.gallery-image > img{
    transition: all ease-in-out 0.5s;
}
.gallery-image:hover > img{
    transition: all ease-in-out 0.5s;
    transform: scale(1.015);
}